import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    resources: {
        Accept: "ACCEPT",
        AddGame: "Add Game",
        Audio: "Audio",
        AudioSettings: "Audio Settings",
        Avatar: "Avatar",
        Back: "Back",
        BackgroundMusic: "Background Music",
        Info: "Info",
        BaseStake: "Base Stake",
        BaseCurrentStake: "Base / Current Stake",
        GameID: "Game ID",
        Type: "Type",
        MaxStake: "Max. Stake",
        Prize: "Prize",
        Cards: "Cards",
        Carpet: "Carpet",
        ChooseATrump: "CHOOSE A TRUMP",
        Classic: "Classic",
        Confirm: "CONFIRM",
        CurrentStake: "Current Stake",
        DoubleYourStrike: "DOUBLE YOUR STAKE",
        English: "English",
        Female: "Female",
        FinalStakeDoubling: "Your final stake after doubling:",
        Floor: "Floor",
        Front: "Front",
        Jazz: "Jazz",
        Leave: "LEAVE",
        LeaveTheGame: "Leave The Game",
        LeaveTheGameConfirmationText: "Do you want to surrender and leave the game?",
        LeaveTheGameButtonText: "Leave The Game",
        Stay: "Stay",
        Male: "Male",
        Off: "Off",
        Player1: "Player 1",
        Player2: "Player 2",
        Premium: "Premium",
        PressRefresh: "PRESS THE REFRESH BUTTON TO CONTINUE THE GAME",
        Rock: "Rock",
        Round: "Round",
        Save: "SAVE",
        Score: "Score",
        Select: "SELECT",
        Selected: "SELECTED",
        Settings: "Settings",
        Themes: "Themes",
        Animation: "Animation",
        Sound: "Sound",
        Square: "Square",
        StakeDoubling: "Would you like to Double the Stake? Your final Stake after doubling",
        StakeDoublingOffer: "Your opponent offers to double the stake.Otherwise you will lose",
        Surrender: "SURRENDER",
        SurrenderLowCase: "Surrender",
        Table: "Table",
        TimeOut: "Time Out",
        ToEnd: "To End",
        Tour: "Tour",
        TourOrder: "Round",
        Tournament: "Tournament",
        TournamentId: "Tournament ID",
        WeakConnection: "Connection is too low",
        Wood: "Wood",
        You: "You",
        Me: "Me",
        Conventions: "Conventions",
        Incognito: "Incognito",
        Private: "Private",
        HiddenCards: "Hidden Cards",
        WithBlindCard: "With Blind Card",
        Attention: "Attention!",
        YourOpponents: "Your Opponent’s",
        ConnectionLost: "Connection lost",
        ConnectionLostResumeMessage: "The game will automatically resume when the connection is restored",
        ConnectionLow: "Connection is too low",
        Refresh: "Refresh",
        StayTuned: "Stay Tuned!",
        GameAutomaticallyResumeConnectionRestored: "the game will automatically resume when the connection is restored",
        Cancel: "Cancel",
        Redouble: "Redouble",
        Double: "Double",
        DoubleYourStake: "Double your stake",
        StakeDoublingMessage: "Would you like to Double the Stake? Your final Stake after doubling",
        StakeDoublingRequestMessage: "Your opponent offers to double the stake. Otherwise you will lose.",
        ChoosingTrump: "Choosing Trump...",
        PleaseWaitUntilOpponentJoin: "Please wait until the opponent will join the game, otherwise you will lose.",
        PleaseWaitUntilPlayersJoin: "Please wait until the players will join the game.",
        GameWillStart: "The Game Will Start",
        PlayerOneTurn: "Player 1 Turn",
        PlayerTwoTurn: "Player 2 Turn",
        FirstPlayerTurn: "You will play first",
        SecondPlayerTurn: "Your will play Second",
        GuestMode: "Guest Mode",
        demo: "demo",
        real: "real",
        game: "game"
    }
};

export const translationSlice = createSlice({
    name: "translationState",
    initialState,
    reducers: {
        saveTranslationLoading: (state, action) => {
            state.loading = action.payload;
        },
        saveTranslation: (state, action) => {
            state.resources = { ...state.resources, ...action.payload };
        }
    }
});

export const { saveTranslation, saveTranslationLoading } = translationSlice.actions;
export default translationSlice.reducer;
