import { urlParams } from "../../helpers";
import { saveStepDefinedAfterCountDown } from "../reducers/gameStartSlice";
import { saveGameData } from "../reducers/gameDataSlice";

const handleCreatePendingStack = () => {
    let stack = [];

    return [
        //add
        value => (stack = [...stack, value]),
        // clear
        () => (stack = []),
        // call
        callback => {
            if (stack?.length) {
                for (let i = 0; i <= stack.length; i++) {
                    stack[i] && typeof stack[i] === "function" && stack[i]();
                }
            }
            stack = [];
            typeof callback === "function" && callback();
        },
        // call first
        () => {
            const firstCall = stack[0];
            typeof firstCall === "function" && firstCall();
            stack.shift();
        },
        // call last
        () => {
            const lastCall = stack[stack.length - 1];
            typeof lastCall === "function" && lastCall();
            stack.pop();
        },
        // get stack length
        () => stack.length
    ];
};

export const [
    addPendingStepControllerStack,
    clearPendingStepControllerStack,
    callPendingStepControllerStack,
    callPendingStepControllerStackFirst,
    callPendingStepControllerStackLast,
    getPendingStepControllerStackLength
] = handleCreatePendingStack();

export const [
    addPendingGameEndControllerStack,
    clearPendingGameEndControllerStack,
    callPendingGameEndControllerStack,
    callPendingGameEndControllerStackFirst
] = handleCreatePendingStack();

export const handleClearAllPendingStacks = () => {
    clearPendingStepControllerStack();
    clearPendingGameEndControllerStack();
};

const handleCallPendingStepFirst = () => {
    const length = getPendingStepControllerStackLength();
    length === 0 && callPendingGameEndControllerStack();
    callPendingStepControllerStackFirst();
};

const animationMiddleware =
    ({ getState }) =>
    next =>
    action => {
        const state = getState();
        const { isHistory } = urlParams;

        const {
            gameStartState: { gameStartCount },
            settingsState: { isAnimationEnabled },
            animationCountState: { stepAnimationCount, dealingAnimationCount }
        } = state;

        if (isHistory) {
            return next(action);
        }

        switch (action.type) {
            case "gameStartState/decrementGameStartCount": {
                if (gameStartCount === 1) {
                    // actions after count down with time out 3000 ms
                    setTimeout(() => {
                        next(saveStepDefinedAfterCountDown()); // for show playing board
                        if (!isAnimationEnabled) {
                            // set game is started after show playing board and call pending steps when animation is disabled
                            next(saveGameData({ isGameStarted: true }));
                            handleCallPendingStepFirst();
                        }
                    }, 3000);
                }
            }
            /* falls through */

            case "animationsCount/decrementDealingAnimationCount": {
                if (dealingAnimationCount === 1) {
                    // set game is started after dealing and call pending steps when animation enabled
                    next(saveGameData({ isGameStarted: true }));
                    handleCallPendingStepFirst();
                }
            }
            /* falls through */

            case "animationsCount/decrementStepAnimationCount": {
                if (stepAnimationCount === 1) {
                    // remove changes hexes after step animations and call pending steps when animation enabled
                    next(saveGameData({ changes: [] }));
                    setTimeout(handleCallPendingStepFirst, 500);
                }
            }
            /* falls through */

            default:
                next(action);
        }
    };

export default animationMiddleware;
