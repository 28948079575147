import { createSlice } from "@reduxjs/toolkit";

const defaultState = { popupType: null, popupLoading: false };
const initialState = { ...defaultState };

export const gamePopupTypeState = createSlice({
    name: "gamePopupTypeState",
    initialState,
    reducers: {
        savePopupType: (state, action) => {
            state.popupType = action.payload;
        },
        savePopupLoading: (state, action) => {
            state.popupLoading = action.payload;
        },
        resetPopupType: () => defaultState
    }
});

export const { savePopupType, savePopupLoading, resetPopupType } = gamePopupTypeState.actions;
export default gamePopupTypeState.reducer;
