const AppConstants = {
    network: {
        CURRENT_GAME_NAME: "hexagon",
        REACT_APP_URL: process.env.REACT_APP_URL,
        REACT_APP_IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
        REACT_APP_TRANSLATIONS_URL: process.env.REACT_APP_TRANSLATIONS_URL,
        network_request_url: {
            API: "api",
            DEMO: "Demo",
            GAME: "Game",
            RULES: "rules",
            HISTORY: "History",
            GET_RULE: "getrule",
            SETTINGS: "Settings",
            GET_HISTORY: "GetHistory",
            GET_SETTINGS: "GetSettings",
            CHANGE_SETTINGS: "ChangeSettings",
            GET_TRANSLATIONS: "GetTranslations"
        },
        socket: {
            GET_INITIAL_STATE: "GetInitialState",
            INITIAL_STATE: "InitialState",
            GET_GAME_DATA: "getGameData",
            GAME_DATA: "GameData",
            HIT_ME: "HitMe",
            STEP: "Step",
            STAND: "Stand",
            BLIND_CARD: "BlindCard",
            DOUBLE_STAKE: "DoubleStake",
            DOUBLE_STAKE_REQUEST: "DoubleStakeRequest",
            ROUND_RESULT: "RoundResult",
            CONNECTION_CHANGED: "ConnectionChanged",
            NEW_ROUND: "NewRound",
            LEAVE_GAME: "LeaveGame",
            DOUBLE_STAKE_RESPONSE: "DoubleStakeResponse",
            CHOOSE_TRUMP: "chooseTrump",
            TRUMP_CHOOSED: "TrumpChoosed",
            MOVE: "Move",
            GAME_RESULT: "GameResult",
            GET_INET_SPEED: "GetInetSpeed",
            INET_SPEED: "InetSpeed",
            SWITCH_BOT: "SwitchBot",
            BOT_SWITCHED: "BotSwitched"
        },
        socketQueries: {
            doubleStakeResponse: {
                double: 1,
                accept: 2,
                redouble: 3,
                surrender: 4
            }
        }
    },
    dimensions: {
        DEFAULT_ASPECT_RATIO_WIDTH_DESKTOP: 16,
        DEFAULT_ASPECT_RATIO_HEIGHT_DESKTOP: 9,
        DEFAULT_ASPECT_RATIO_WIDTH_MOBILE: 22.5,
        DEFAULT_ASPECT_RATIO_HEIGHT_MOBILE: 9,
        FONT_SIZE_SCALE_INDEX_DESKTOP: 1538,
        FONT_SIZE_SCALE_INDEX_LANDSCAPE: 812,
        FONT_SIZE_SCALE_INDEX_PORTRAIT: 375
    },
    devicesMode: {
        DESKTOP: "desktop",
        MOBILE: "mobile"
    },
    popupTypes: {
        SETTINGS: "settings",
        DOUBLE_STAKE_CONFIRMATION: "doubleStakeConfirmation",
        DOUBLE_STAKE: "doubleStake",
        LEAVE_GAME: "leaveGame",
        GAME_OVER: "gameOver",
        REVANCHE_OFFER: "revancheOffer",
        LOST_CONNECTION: "lostConnection",
        LOW_CONNECTION: "lowConnection",
        CHOOSE_TRUMP: "chooseTrump"
    },
    boardLengths: {
        0: 7,
        1: 9,
        2: 9
    },
    hexStatuses: ["pure", "player", "opponent", "firstWave", "secondWave", "selected"],
    gameTypeNames: {
        0: "Hex 37",
        1: "Hex 61",
        2: "Hex 43"
    },
    GAME_START_COUNT: 3
};

export default AppConstants;

export const BROWSER_NAME = (() => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
    } else {
        browserName = "No browser detection";
    }

    return browserName;
})();

export const IS_IOS = (() => /iPad|iPhone|iPod/.test(navigator.userAgent))();

export const IS_IOS_CHROME = IS_IOS && BROWSER_NAME === "chrome";

export const [HIDDEN, VISIBILITY_CHANGE] = (() => {
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    return [hidden, visibilityChange];
})();

export const VALIDATION_COOKIE_NAME = "_ga_anl_cf3q";
