import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dealingAnimationCount: 0,
    stepAnimationCount: 0
};

const slice = createSlice({
    name: "animationsCount",
    initialState: { ...initialState },
    reducers: {
        incrementDealingAnimationCount: state => {
            state.dealingAnimationCount++;
        },
        decrementDealingAnimationCount: state => {
            state.dealingAnimationCount = state.dealingAnimationCount > 0 ? state.dealingAnimationCount - 1 : 0;
        },
        incrementStepAnimationCount: state => {
            state.stepAnimationCount++;
        },
        decrementStepAnimationCount: state => {
            state.stepAnimationCount = state.stepAnimationCount > 0 ? state.stepAnimationCount - 1 : 0;
        },
        resetAnimationsCount: () => initialState
    }
});

export const {
    incrementDealingAnimationCount,
    decrementDealingAnimationCount,
    incrementStepAnimationCount,
    decrementStepAnimationCount,
    resetAnimationsCount
} = slice.actions;

export default slice.reducer;
