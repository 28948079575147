import AppConstants, { VALIDATION_COOKIE_NAME } from "../constants/index";
import { handleIsDynamicUrl } from "../helpers";

const {
    network: { REACT_APP_URL }
} = AppConstants;

const createUrl = (urlPaths, query, identityUrl) => {
    let url = identityUrl || handleIsDynamicUrl({ REACT_APP_URL });

    url = Array.isArray(urlPaths) ? [url, ...urlPaths].join("/") : `${url}/${urlPaths}`;

    if (query) {
        for (let key in query) {
            url = `${url}&${key}=${query[key]}`;
        }
        url = url.replace("&", "?");
    }

    return url;
};

const makeAPIRequest = (options = {}) => {
    const { method, identityUrl, urlPaths, query, body } = options;
    const requestURL = createUrl(urlPaths, query, identityUrl);

    const fetchOptions = {
        method: method || "GET",
        headers: {
            "Content-Type": "application/json",
            [VALIDATION_COOKIE_NAME]: getValidationCookie()
        },
        body: JSON.stringify(body)
    };

    return fetch(requestURL, fetchOptions)
        .then(res => res.json())
        .then(res => {
            if (res.Error || res.status === 400) {
                throw new Error(res.Error || res.title);
            }

            return Promise.resolve(res);
        })
        .catch(err => Promise.reject(err));
};

export const makeAPIGetRequest = data => {
    const options = data || {};
    options.method = "GET";
    return makeAPIRequest(options);
};

export const makeAPIPostRequest = data => {
    const options = data || {};
    options.method = "POST";
    return makeAPIRequest(options);
};

export const getValidationCookie = () => {
    return localStorage.getItem(VALIDATION_COOKIE_NAME);
};
