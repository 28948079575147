import { createSlice } from "@reduxjs/toolkit";

export const gameInitialStateSlice = createSlice({
    name: "gameInitialState",
    initialState: {},
    reducers: {
        saveInitialState: (state, action) => ({
            ...state,
            ...action.payload
        })
    }
});

export const { saveInitialState } = gameInitialStateSlice.actions;
export default gameInitialStateSlice.reducer;
