import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updater: false,
    player: null,
    opponent: null
};

export const usersTimesSlice = createSlice({
    name: "usersTimesState",
    initialState: { ...initialState },
    reducers: {
        saveUsersTimes: (state, action) => ({
            ...state,
            ...action.payload,
            updater: !state.updater
        }),
        resetUsersTimes: (state, action) => ({
            ...initialState,
            ...action.payload
        })
    }
});

export const { saveUsersTimes, resetUsersTimes } = usersTimesSlice.actions;
export default usersTimesSlice.reducer;
