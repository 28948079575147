import { shallowEqual, useSelector } from "react-redux";
import AppConstants from "../../constants";
import { translate, urlParams } from "../../helpers";

const {
    gameTypeNames,
    network: {
        socketQueries: {
            doubleStakeResponse: { accept }
        }
    }
} = AppConstants;

const { isHistory, win } = urlParams;

export const useShallowSelector = selector => useSelector(selector, shallowEqual);

export const selectAppRootData = state => {
    const { gameDataState, gameInitialState } = state;

    return {
        isGameEnded: gameDataState?.finishStatus,
        gameConfigIsDemo: gameInitialState?.gameConfiguration?.isDemo && !isHistory
    };
};

export const selectAppData = state => {
    const { settingsState, translationState, gameInitialState, gameDataState } = state;
    const { loadingAfterVisible, loading } = gameDataState;
    const { gameConfiguration, tournamentInfo } = gameInitialState;

    const { tournamentId } = tournamentInfo || {};
    const apiCallLoading = settingsState?.loading || translationState.loading;
    const appLoading = loadingAfterVisible || (loading && tournamentId);
    const isHaveConfiguration = !!gameConfiguration;

    return {
        appLoading,
        apiCallLoading,
        isHaveConfiguration
    };
};

export const selectCancelGameWaitingData = state => {
    const { gameInitialState, gameDataState, dataReceivedState } = state;
    const { isGuest, tournamentInfo } = gameInitialState ?? {};
    const { finishStatus, isGameStarted, loading } = gameDataState ?? {};

    const isValidShowCancelGame =
        (!tournamentInfo?.tournamentId &&
            !dataReceivedState.dataReceived &&
            !isGameStarted &&
            !finishStatus &&
            !isHistory) ||
        loading;

    return {
        isValidShowCancelGame,
        isGuest
    };
};

export const selectConnectionLostData = state => {
    const gameInitialState = state.gameInitialState;

    return {
        initialState: gameInitialState,
        isGuest: gameInitialState?.isGuest
    };
};

export const selectGameContainerData = state => {
    const { settingsState, gameInitialState, gameStartState, gameDataState } = state;

    const {
        isGuest,
        gameConfiguration: { gameType }
    } = gameInitialState;
    const { isGameStarted, finishStatus } = gameDataState;
    const { isStepDefinedAfterCountDown } = gameStartState;
    const { isAnimationEnabled, currentTheme } = settingsState ?? {};

    const isGameEnded = !!finishStatus;
    const isShowCountDown = !isGameStarted && typeof isGameStarted === "boolean" && !isStepDefinedAfterCountDown;

    return {
        isGuest,
        isGameEnded,
        gameType,
        currentTheme,
        isShowCountDown,
        isAnimationEnabled
    };
};

export const selectTimerData = state => {
    const { usersTimesState: usersTimes, gameDataState, gameInitialState } = state;
    const { finishStatus, isPlayerTurn, isRoundEnded, isPlayerPlayed } = gameDataState ?? {};
    const {
        gameConfiguration: { gameTime }
    } = gameInitialState;

    const activeUserType = isHistory
        ? typeof isPlayerPlayed === "boolean"
            ? isPlayerPlayed
                ? "player"
                : "opponent"
            : undefined
        : typeof isPlayerTurn === "boolean"
        ? !finishStatus && !isRoundEnded && isPlayerTurn
            ? "player"
            : "opponent"
        : undefined;

    return {
        gameTime,
        usersTimes,
        finishStatus,
        isPlayerTurn,
        isRoundEnded,
        activeUserType
    };
};

export const selectInfoPopoverData = state => {
    const { gameDataState, gameInitialState } = state;

    const { doubleStakeData } = gameDataState;
    const { gameConfiguration } = gameInitialState ?? {};
    const { maxStakeDoubling, incognito, gameType } = gameConfiguration ?? {};

    const conventionsData = {
        MaxStakeDoubling: maxStakeDoubling > 1 && maxStakeDoubling,
        Private: gameConfiguration?.private,
        Incognito: incognito
    };

    return {
        conventionsData,
        gameType: gameTypeNames[gameType],
        currentStake: doubleStakeData?.currentStake || 1
    };
};

export const selectSidebarData = state => {
    const { gameDataState, gameInitialState, gameStartState } = state;
    const { isStepDefinedAfterCountDown } = gameStartState;

    const {
        finishStatus,
        isPlayerTurn,
        isRoundEnded,
        player,
        opponent,
        isGameStarted,
        isPlayerPlayed,
        doubleStakeData
    } = gameDataState ?? {};
    const { isGuest, playerInitialState, opponentInitialState, gameConfiguration } = gameInitialState ?? {};

    const isHideTimer = !isStepDefinedAfterCountDown && !isGameStarted && !isHistory;

    const { playerNickName } = playerInitialState;
    const { playerNickName: opponentNickName } = opponentInitialState;
    const { incognito } = gameConfiguration;

    const activeUserType = isHistory
        ? typeof isPlayerPlayed === "boolean"
            ? isPlayerPlayed
                ? "player"
                : "opponent"
            : undefined
        : typeof isPlayerTurn === "boolean"
        ? !finishStatus && !isRoundEnded && isPlayerTurn
            ? "player"
            : "opponent"
        : undefined;

    const opponentName = isGuest || isHistory ? "Player2" : "You";
    const opponentText = isHistory || (isGuest && !incognito) ? `${opponentNickName}` : translate(opponentName);

    const playerName = isGuest || isHistory ? "Player1" : "Me";
    const playerText = isHistory || (isGuest && !incognito) ? `${playerNickName}` : translate(playerName);

    return {
        isGuest,
        playerText,
        opponentText,
        isHideTimer,
        activeUserType,
        doubleStakeData,
        playerPoints: player?.point || 0,
        opponentPoints: opponent?.point || 0
    };
};

export const selectSettingsPopoverData = state => {
    const { soundEnabled, isAnimationEnabled, currentTheme, themes, resRejected } = state.settingsState ?? {};

    return { soundEnabled, isAnimationEnabled, currentTheme, themes, settingRejected: resRejected };
};

export const countDownData = state => {
    const { gameDataState, gameStartState, gameInitialState, settingsState } = state;

    const { currentTheme } = settingsState;
    const { isPlayerDealer } = gameDataState;
    const { isGuest } = gameInitialState;
    const { gameStartCount } = gameStartState;

    return { isPlayerDealer, gameStartCount, isGuest, currentTheme };
};

export const selectPlayingBoardData = state => {
    const { gameDataState, settingsState, gameInitialState, gameStartState } = state;

    const { isStepDefinedAfterCountDown } = gameStartState;
    const { isAnimationEnabled, currentTheme } = settingsState;
    const { boardMatrix, isGameStarted, changes, isPlayerTurn, finishStatus, isPlayerDealer } = gameDataState;
    const {
        isGuest,
        gameConfiguration: { gameType }
    } = gameInitialState;

    const optionsForceSelected = isPlayerDealer && isStepDefinedAfterCountDown && !isGuest && !isHistory;

    return {
        isGuest,
        changes,
        gameType,
        boardMatrix,
        currentTheme,
        isPlayerTurn,
        isGameStarted,
        isAnimationEnabled,
        optionsForceSelected,
        isGameEnded: finishStatus
    };
};

export const selectNotificationData = state => {
    const { connectionState, gameInitialState, gameDataState } = state;
    const {
        isPlayerWin,
        player: { notification: playerNotification },
        opponent: { notification: opponentNotification }
    } = gameDataState;
    const { isGuest } = gameInitialState ?? {};
    const { player: playerConnection, opponent: opponentConnection } = connectionState;

    const winnerName = isPlayerWin ? "player" : "opponent";
    const winnerUser = typeof isPlayerWin === "boolean" ? winnerName : null;

    return {
        isGuest,
        winnerUser: winnerUser,
        playerNotification: winnerUser === "player" ? "Winner" : playerNotification,
        opponentNotification: winnerUser === "opponent" ? "Winner" : opponentNotification,
        playerConnectionLost: !playerConnection,
        opponentConnectionLost: !opponentConnection
    };
};

export const historyData = state => {
    const { historyState } = state;
    const { history, stepIndex, roundIndex, autoPlayEnabled } = historyState;

    return {
        rounds: history.rounds || [],
        stepIndex,
        roundIndex,
        autoPlayEnabled
    };
};

export const backgroundData = state => {
    const { settingsState, gameInitialState } = state;
    const { currentTheme } = settingsState;
    const {
        gameConfiguration: { gameType }
    } = gameInitialState;

    return {
        currentTheme,
        gameType
    };
};

export const actionButtonsData = state => {
    const { gameDataState, gameInitialState, animationCountState } = state;

    const { finishStatus, isPlayerTurn, isGameStarted, doubleStakeData, popups } = gameDataState ?? {};
    const { doubleStake } = popups || {};
    const {
        gameConfiguration: { maxStakeDoubling }
    } = gameInitialState;
    const { stepAnimationCount } = animationCountState;
    const { isMaxDoubleStake, isPlayer: isCanDouble } = doubleStakeData || {};

    const { status } = doubleStake || {};

    return {
        isCanDouble,
        isPlayerTurn,
        isGameStarted,
        isMaxDoubleStake,
        maxStakeDoubling,
        stepAnimationCount,
        isGameEnded: finishStatus,
        showDoubleStackConfirmation: status && status !== accept
    };
};

export const popupWrapperData = state => {
    const { gameDataState, popupTypesState } = state;

    const { popupType, popupLoading } = popupTypesState;
    const { popups, doubleStakeData } = gameDataState ?? {};
    const { canRedouble } = popups?.doubleStake || {};
    const currentStake = doubleStakeData?.currentStake || 1;

    const prizeAfterDouble = currentStake * win * 2;

    return {
        isCanRedouble: canRedouble,
        popupType,
        popupLoading,
        prizeAfterDouble
    };
};

export const headingData = state => {
    const { settingsState, gameInitialState } = state;

    const { isGuest, gameConfiguration } = gameInitialState;
    const { soundEnabled } = settingsState ?? {};
    const { isDemo } = gameConfiguration ?? {};

    return {
        isGuest,
        soundEnabled,
        gameConfigIsDemo: isDemo && !isHistory
    };
};

export const selectActionsContainerData = state => {
    const { gameInitialState } = state;

    const { isGuest, gameConfiguration } = gameInitialState;
    const { isDemo } = gameConfiguration ?? {};

    return {
        isGuest,
        gameConfigIsDemo: isDemo
    };
};

export const selectToggleSwitchDemoData = state => {
    const { demoGameState } = state;

    const { isSwitched, loading } = demoGameState;

    return {
        isSwitched,
        loading
    };
};
