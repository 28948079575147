import AppConstants from "../constants";
import { store } from "../store/store";

const {
    dimensions: {
        DEFAULT_ASPECT_RATIO_HEIGHT_MOBILE,
        DEFAULT_ASPECT_RATIO_WIDTH_MOBILE,
        DEFAULT_ASPECT_RATIO_HEIGHT_DESKTOP,
        DEFAULT_ASPECT_RATIO_WIDTH_DESKTOP,
        FONT_SIZE_SCALE_INDEX_DESKTOP,
        FONT_SIZE_SCALE_INDEX_LANDSCAPE,
        FONT_SIZE_SCALE_INDEX_PORTRAIT
    },
    boardLengths
} = AppConstants;

const gameResolutionMobile = DEFAULT_ASPECT_RATIO_WIDTH_MOBILE / DEFAULT_ASPECT_RATIO_HEIGHT_MOBILE;
const gameResolutionDesktop = DEFAULT_ASPECT_RATIO_WIDTH_DESKTOP / DEFAULT_ASPECT_RATIO_HEIGHT_DESKTOP;

export const urlParams = (() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { isMobile, isDemo } = params;

    params.isMobile = (isMobile && isMobile.toLowerCase()) === "true" || false;
    params.isDemo = (isDemo && isDemo.toLowerCase()) === "true" || false;
    params.isHistory = window.location.pathname.includes("/game/history");
    return params;
})();

export const translate = (() => {
    let translationState = null;

    return {
        t: key => {
            if (!translationState) {
                translationState = store.getState().translationState.resources;
            }

            return translationState[key] || key;
        }
    };
})().t;

const setRootFontSize = (gameWidth, gameHeight, isMobile, isPortrait) => {
    const rootElement = document.querySelector("html");
    const mobileSizeIndex = isPortrait ? FONT_SIZE_SCALE_INDEX_PORTRAIT : FONT_SIZE_SCALE_INDEX_LANDSCAPE;
    const sizeIndex = isMobile ? mobileSizeIndex : FONT_SIZE_SCALE_INDEX_DESKTOP;

    const demissionPortrait = gameWidth / gameHeight;
    const fontSizeDemission = gameWidth / sizeIndex;

    const calcDemissionForPortrait = isPortrait && demissionPortrait > 0.7;
    const calcDem = fontSizeDemission - demissionPortrait;
    const portraitCalcDem = calcDem > 0.9 ? calcDem : 0.9;

    rootElement.style.fontSize = `${
        calcDemissionForPortrait && gameWidth > 350 ? portraitCalcDem : fontSizeDemission
    }px`;
};

export const calculateGameDimension = (clientWidth, clientHeight) => {
    const { isMobile } = urlParams;

    const isPortrait = isMobile && clientWidth < clientHeight;
    const isFullWidth = clientWidth / clientHeight < gameResolutionDesktop;
    const dimensionsConfig = {
        gameWidth: isFullWidth ? clientWidth : clientHeight * gameResolutionDesktop,
        gameHeight: !isFullWidth ? clientHeight : clientWidth / gameResolutionDesktop
    };

    dimensionsConfig.fontSize = dimensionsConfig.gameWidth / FONT_SIZE_SCALE_INDEX_LANDSCAPE;

    if (isMobile) {
        if (isPortrait) {
            const isFullHeight = clientHeight / clientWidth < gameResolutionMobile;

            dimensionsConfig.gameWidth = isFullHeight ? clientWidth : clientHeight / gameResolutionMobile;
            dimensionsConfig.gameHeight = isFullHeight ? clientHeight : clientWidth * gameResolutionMobile;
        } else {
            const isWithFullWidth = clientWidth / clientHeight < gameResolutionMobile;

            dimensionsConfig.gameWidth = isWithFullWidth ? clientWidth : clientHeight * gameResolutionMobile;
            dimensionsConfig.gameHeight = isWithFullWidth ? clientHeight : clientWidth / gameResolutionMobile;
        }
    }

    setRootFontSize(dimensionsConfig.gameWidth, dimensionsConfig.gameHeight, isMobile, isPortrait);
    return { ...dimensionsConfig, clientWidth, clientHeight };
};

export const getCardPositionCalcStyle = ({ index, disableRotation, cards, isPlayer }) => {
    const { isMobile } = urlParams;
    const { clientWidth, clientHeight } = document.body;
    const isPortrait = isMobile && clientWidth < clientHeight;
    const xPoint = isMobile ? 32 : 40;
    let [cardsLength, lastCardCalc, degPoint, xPosPoint] = [cards?.length, 1, 2, isPortrait ? 35 : xPoint];
    const [calcIndexPoint, cardsIsOdd] = [index - cardsLength / 2, cardsLength % 2 === 1];
    const yPosIndex = Math.floor(Math.abs(calcIndexPoint)) - +(calcIndexPoint < 0);
    const cardsYPositionsCalc = [...cards]
        .splice(0, cardsLength / 2)
        ?.map((elem, ind) => (lastCardCalc = ind + lastCardCalc));

    const [calcIndexInOdd, yPos] = [
        cardsIsOdd ? Math.ceil(calcIndexPoint) : calcIndexPoint,
        cardsYPositionsCalc[yPosIndex] || 0
    ];

    let [translateX, rotate] = [xPosPoint * calcIndexInOdd, degPoint * calcIndexInOdd];

    if (!cardsIsOdd) {
        translateX += xPosPoint / 2;
        rotate += degPoint / 2;
    }

    const rotationValue = isPlayer ? rotate : -rotate;
    const rotationPoint = disableRotation ? 0 : rotationValue;

    return {
        transform: `translate(${translateX}%, ${isPlayer ? Math.abs(yPos) : -yPos}%) rotate(${rotationPoint}deg)`
        // zIndex: index + 1
    };
};

const separateHost = () => {
    let host = window.location.host.split(".");
    if (host.length > 2) {
        host.shift();
    }
    host = host.join(".");
    return host;
};

export const handleIsDynamicUrl = urlData => {
    const key = Object.keys(urlData)[0];
    let url = urlData[key];

    if (process.env[`${key}_IS_DYNAMIC`] === "true") {
        const host = separateHost();
        url = `${url}${host}`;
    }

    return url;
};

export const existsFile = url => {
    const http = new XMLHttpRequest();
    http.open("HEAD", url, false);
    http.send();
    return http.status !== 404;
};

export const callWithTimeoutRejection = (apiCall, timeout = 3000) => {
    const timeoutPromise = new Promise((resolve, reject) =>
        setTimeout(() => reject(new Error("Timeout to receive response has expired")), timeout)
    );

    const resultPromise = apiCall();

    return Promise.race([timeoutPromise, resultPromise]);
};

export const getHexIdByCords = (hexIndex, rowIndex, gameType) => {
    const boardLength = boardLengths[gameType];

    return hexIndex > -1 && hexIndex < boardLength && rowIndex > -1 && rowIndex < boardLength
        ? boardLength * rowIndex + hexIndex
        : -1;
};

export const getCordsByHexId = (hexId, gameType) => {
    const boardLength = boardLengths[gameType];
    return { rowIndex: Math.floor(hexId / boardLength), hexIndex: hexId % boardLength };
};

export const detectWave = (hexId, waveIndex, gameType) => {
    const { rowIndex, hexIndex } = getCordsByHexId(hexId, gameType);
    const isRowEven = rowIndex % 2 === 0;
    const returnValue = [];
    const loopCount = waveIndex * 2 + 1;

    for (let i = 0; i < loopCount; ++i) {
        const rowDif = waveIndex - i;
        const rowDifAbc = Math.abs(rowDif);
        const row = rowIndex - rowDif;

        if (rowDifAbc === waveIndex) {
            const loopStart = isRowEven ? Math.floor((waveIndex + 1) / 2) * -1 : Math.floor(waveIndex / 2) * -1;
            const loopEnd = isRowEven ? Math.floor(waveIndex / 2) + 1 : Math.ceil(waveIndex / 2) + 1;
            for (let j = loopStart; j < loopEnd; ++j) {
                const hex = hexIndex + j;
                const idToPush = getHexIdByCords(hex, row, gameType);
                if (idToPush > -1) {
                    returnValue.push(idToPush);
                }
            }
        } else {
            const all = 2 * waveIndex - rowDifAbc;
            const hexOneDif = Math.round(all / 2);
            const hexTwoDif = all - hexOneDif;
            const idToPush1 = getHexIdByCords(hexIndex - hexOneDif * (isRowEven ? 1 : -1), row, gameType);
            const idToPush2 = getHexIdByCords(hexIndex + hexTwoDif * (isRowEven ? 1 : -1), row, gameType);
            if (idToPush1 > -1) {
                returnValue.push(idToPush1);
            }
            if (idToPush2 > -1) {
                returnValue.push(idToPush2);
            }
        }
    }

    return returnValue;
};

export const sortHexesByWaves = ({ boardMatrix, hexArgs, gameType }) => {
    const { rowIndex, index, waveIndex } = hexArgs ?? {};
    const returnValue = [];
    const centralIndex = Math.floor(boardMatrix?.length / 2);
    const xCoordinate = rowIndex ?? centralIndex;
    const yCoordinate = index ?? centralIndex;
    const waveCount = (waveIndex ?? centralIndex) + 1;
    const hexId = getHexIdByCords(yCoordinate, xCoordinate, gameType);

    waveIndex || returnValue.push([hexId]);

    for (let i = 1; i < waveCount; ++i) {
        returnValue.push(detectWave(hexId, i, gameType));
    }

    return returnValue;
};

export const findWaveIndexByHexId = (waves, hexId) => {
    if (waves) {
        for (let i = 0, len = waves?.length; i < len; ++i) {
            if (waves[i]?.indexOf(hexId) > -1) {
                return i + 1;
            }
        }
    }
};

export const debounce = (() => {
    let isValidForCall = true;
    let timeout = null;

    return (callback, wait = 200) => {
        if (wait === 0) {
            return callback();
        }

        if (isValidForCall) {
            callback();
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                isValidForCall = true;
            }, wait);
        }
        isValidForCall = false;
    };
})();
