import { batch } from "react-redux";
import { store } from "../store/store";
import AppConstants from "../constants";
import { urlParams } from "../helpers";
import { makeAPIGetRequest } from "../services/network";
import { saveLoading, saveSwitchMode } from "../store/reducers/demoGameSlice";

const {
    network: {
        network_request_url: { API, DEMO, GAME }
    }
} = AppConstants;

const demoGameController = res => {
    const { dispatch } = store;
    batch(() => {
        dispatch(saveSwitchMode(res.isSwitched));
        dispatch(saveLoading(false));
    });
};

export default demoGameController;

export const handleStart = async () => {
    const { isMobile, language } = urlParams;

    const options = {
        urlPaths: [API, DEMO, GAME],
        query: { isMobile, language }
    };

    try {
        const res = await makeAPIGetRequest({ ...options });
        window.location.href = res.url;
    } catch (error) {
        console.log(error);
    }
};
