import { createSlice } from "@reduxjs/toolkit";
import AppConstants from "../../constants";

const { GAME_START_COUNT } = AppConstants;

const initialState = {
    gameStartCount: GAME_START_COUNT,
    isStepDefinedAfterCountDown: false
};

export const gameStartSlice = createSlice({
    name: "gameStartState",
    initialState,
    reducers: {
        saveStepDefinedAfterCountDown: state => {
            state.isStepDefinedAfterCountDown = true;
        },
        decrementGameStartCount: state => {
            state.gameStartCount--;
        }
    }
});

export const { decrementGameStartCount, saveStepDefinedAfterCountDown } = gameStartSlice.actions;
export default gameStartSlice.reducer;
