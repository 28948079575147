import { createSlice, createSelector } from "@reduxjs/toolkit";
import { urlParams } from "../../helpers";

const initialState = {
    player: {},
    changes: [],
    opponent: {},
    loading: false,
    loadingAfterVisible: false
};

export const gameDataSlice = createSlice({
    name: "gameDataState",
    initialState: { ...initialState },
    reducers: {
        saveGameData: (state, action) => ({
            ...state,
            ...action.payload
        }),
        saveGameDataLoading: (state, action) => {
            state.loading = action.payload;
        },
        saveLoadingAfterVisible: (state, action) => {
            state.loadingAfterVisible = action.payload;
        },
        resetGameDateState: state => ({
            ...initialState,
            isGameStarted: state.isGameStarted
        })
    },
    extraReducers: builder => {
        builder.addMatcher(
            action => action.type === "gameDataState/saveGameDataLoading",
            state => {
                state.loadingAfterVisible = false;
            }
        );
    }
});

const { win } = urlParams || {};
const gameDataSelect = state => state.gameDataState;

export const selectPrizeWithDoubleStack = createSelector(
    gameDataSelect,
    gameDataState => (gameDataState.doubleStakeData?.currentStake || 1) * win
);

export const { saveGameData, resetGameDateState, saveGameDataLoading, saveLoadingAfterVisible } = gameDataSlice.actions;
export default gameDataSlice.reducer;
