import React from "react";
import PropTypes from "prop-types";
import desktopBg from "../../assets/images/backgrounds/theme1/web/board-full.jpg";
import mobileBg from "../../assets/images/backgrounds/theme1/mobile/board-full.jpg";
import ImageWithErrorLoad from "../imageWithErrorLoad/ImageWithErrorLoad";
import { urlParams } from "../../core/helpers";

const StartDemo = ({ onClick }) => {
    const { isMobile } = urlParams;

    return (
        <div className="start-demo-container" data-testid="start-demo">
            <button className="start-button" onClick={onClick}>
                START
            </button>
            <ImageWithErrorLoad alt="board" src={isMobile ? mobileBg : desktopBg} />
        </div>
    );
};

StartDemo.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default StartDemo;
