import { configureStore } from "@reduxjs/toolkit";
import animationMiddleware from "./middleware/animationMiddleware";

import historyState from "./reducers/historySlice";
import settingsState from "./reducers/settingsSlice";
import demoGameState from "./reducers/demoGameSlice";
import gameDataState from "./reducers/gameDataSlice";
import gameStartState from "./reducers/gameStartSlice";
import connectionState from "./reducers/connectionSlice";
import popupTypesState from "./reducers/popupTypesSlice";
import usersTimesState from "./reducers/usersTimesSlice";
import translationState from "./reducers/translationSlice";
import gameInitialState from "./reducers/gameInitialStateSlice";
import dataReceivedState from "./reducers/dataReceivedSlice";
import lowConnectionState from "./reducers/lowConnectionSlice";
import animationCountState from "./reducers/animationsCounterSlice";

export const reducer = {
    historyState,
    settingsState,
    gameDataState,
    demoGameState,
    gameStartState,
    usersTimesState,
    connectionState,
    popupTypesState,
    translationState,
    gameInitialState,
    dataReceivedState,
    lowConnectionState,
    animationCountState
};

export const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV === "development",
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(animationMiddleware)
});
